.box {
  border: 0px solid #d5d5d5;
  padding: 10px;
  margin: 10px;
  text-align: center;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  border: 0px;
  border-radius: 4px;
}
.box .box1 {
  border-left: 10px solid orange !important;
}
.card-task {
  background-color: #fff;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 0px;
  border: 0px;
  border-radius: 4px;
}
.card-task .card-title {
  font-size: 20px;
  color: #333;
  font-weight: 500;
  fill: #333;
}
.card-task .card-subtitle {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  fill: #333;
}
.card-task .addServices-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 15px !important;
  padding: 8px 14px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}
.card-task .addServices-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .bg-td-green {
  background-color: #dcfffb;
}
.card-task .txt-done {
  color: #03335b;
}
.card-task .txt-green {
  color: #2dbf02;
}
.card-task .txt-red {
  color: #d60023;
}
.card-task .txt-blue {
  color: #05f;
}
.card-task .txt-yellow {
  color: #ffc400;
}
.card-task .txt-skyblue {
  color: #578fff;
}
.card-task .complated-btn {
  color: #333 !important;
  background: #00f6ff !important;
  border: 1px solid #00f6ff !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .complated-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .bookTest-btn {
  color: #fff !important;
  background: #7ec962 !important;
  border: 1px solid #7ec962 !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .bookTest-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .Booked-btn {
  color: #fff !important;
  background: #578fff !important;
  border: 1px solid #578fff !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .Booked-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .viewNotes-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .viewNotes-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .setTarget-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .setTarget-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .cross-btn-close {
  background-color: #acacac;
  padding: 7px;
  color: #fff !important;
  border-radius: 20px;
  font-size: 10px;
}
.card-task .btn-info {
  color: #fff !important;
  background: #578fff !important;
  border: 1px solid #578fff !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .btn-info:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .btn-danger {
  color: #fff !important;
  background: #d60023 !important;
  border: 1px solid #d60023 !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .btn-danger:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .btn-success {
  color: #fff !important;
  background: #3552b5 !important;
  border: 1px solid #3552b5 !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .btn-success:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.card-task .btn-secondary {
  color: #fff !important;
  background: #6c757d !important;
  border: 1px solid #6c757d !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 500 !important;
}
.card-task .btn-secondary:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.profile-card {
 /*display: flex;
  */
  background-color: #fff;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px;
}
.profile-image {
  flex: 0 0 20%;
  position: relative;
}
.profile-image img {
  display: block;
  border-radius: 10px;
  object-fit: cover;
  height: 180px;
  width: 160px;
}
.info-line {
 /*margin-bottom: 10px;
 */
  line-height: 1.5rem;
  text-align: left;
}
.info {
  flex: 0 0 77%;
}
.name {
  font-size: 18px;
  font-weight: bold;
}
.location {
  font-size: 12px;
  color: #666;
  padding-left: 10px;
}
.fa-map-marker, .fa-envelope {
  padding-left: 10px;
  padding-right: 10px;
}
.pad-right-10 {
  padding-right: 10px;
}
.big-text {
  font-size: 16px;
}
.phone, .email, .membership-id, .dates, .gender, .physical, .program, .status {
  font-size: 12px;
  color: #333;
  padding-right: 10px;
}
.risk-badge {
  display: flex;
  align-items: center;
  flex: 0 0 23%;
  justify-content: flex-end;
  flex-direction: column;
}
.risk-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  margin-right: 5px;
  text-align: center;
  line-height: 16px;
  flex-direction: column;
}
.risk-circle span {
  color: #333;
}
.risk-label {
  font-size: 12px;
  text-align: right;
}
.actions {
  margin-top: 10px;
}
.action-button {
  color: #fff;
  border: none;
  padding: 6px 10px;
  font-size: 12px;
  border-radius: 10px;
  line-height: 1rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* max-width: -webkit-fill-available; */
  /* width: 100%; */
  justify-content: center;
}
.green-btn {
  background-color: #017221;
}
.green-btn:hover {
  background-color: #1279be;
}
.red-btn {
  background-color: #d60023;
}
.red-btn:hover {
  background-color: #1279be;
}
.blue-btn {
  background-color: #03335b !important;
}
.blue-btn:hover {
  background-color: #1279be !important;
}
.button-row {
  /* display: flex;
  justify-content: space-between;
  gap: 8px; */

  gap: 8px;
  display: flex;
  flex-wrap: wrap;
}
.hide {
  display: none !important;
}
.fa-pencil {
  cursor: pointer;
}
.messageDiv {
  background-color: transparent !important;
  color: #017221;
  padding: 0px !important;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid transparent !important;
}
.errorDiv {
  background-color: transparent;
  color: #d60023 !important;
  padding: 0px !important;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 5px;
  border-radius: 10px;
  border: 1px solid transparent !important;
}
.coach-call-box-1 .user-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 230px;
  display: block;
  height: 72px;
  object-fit: cover;
  width: 72px;
}
.coach-call-box-1 .user-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 0rem;
  text-align: left;
}
.coach-call-box-1 .user-card-subtitle {
  font-size: 16px;
  font-weight: 500;
  color: #636773;
  margin-bottom: 0rem;
  line-height: 1.7;
  text-align: left;
}
.coach-call-box-1 .user-card-text {
  font-size: 16px;
  color: #333;
  margin-bottom: 0.3rem;
  font-weight: 600;
  text-align: center;
}
.coach-call-box-1 .user-card-text span {
  font-size: 14px;
  color: #343434;
  margin-top: 0rem;
  font-weight: 400;
  text-align: center;
}
.care-navigator-consultation-box {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 14px;
}
.coach-call-box-1 .consultation-pics-box {
  height: 196px;
  position: relative;
  width: 196px;
}
.coach-call-box-1 .consultation-pics-img {
  background-color: #fff;
  border: 1px solid #e8edf7;
  border-radius: 24px;
  display: block;
  height: 196px;
  object-fit: cover;
  width: 196px;
}
.call-disposition-title {
  color: #6a6a6a;
  margin-bottom: 0rem;
  font-weight: 500;
  text-align: left;
  font-size: 18px;
}
.call-disposition-subtitle {
  color: #6a6a6a;
  margin-bottom: 0.3rem;
  font-weight: 400;
  text-align: left;
  font-size: 14px;
}
.call-disposition-status {
  width: 100%;
}
.control-input-ui-call {
  border: 1px solid #bbb !important;
  border-radius: 14px !important;
  color: #fff !important;
  font-size: 18px !important;
  background-color: transparent !important;
  padding: 8px 12px !important;
}
.control-input-ui-call::placeholder {
  color: #bbb !important;
}
table.table-expandable > tbody > tr > td:first-child {
  cursor: pointer;
}
table.table-expandable > tbody > tr > td:first-child:hover {
  background-color: #1279be;
  color: #fff;
}
.pathways-table-expandable span:last-child {
  border-right: 0px solid #b1b1b1;
}
.pathways-table-expandable-td-bg {
  --bs-table-bg-type: transparent !important;
}
.pathways-table-expandable-td-bg:hover {
  background-color: transparent !important;
  cursor: text !important;
}
.addNewRow-btn {
  color: #02335b !important;
  background: #fff !important;
  border: 0 !important;
  border-bottom: 1px solid #02335b !important;
  font-size: 15px !important;
  padding: 4px 0px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
}
.addNewRow-btn:hover {
  color: #1279be !important;
  background: #fff !important;
  border: 0 !important;
  border-bottom: 1px solid #1279be !important;
}
.cross-btn-close {
  background-color: #acacac;
  padding: 7px;
  color: #fff !important;
  border-radius: 20px;
  font-size: 10px;
}


#wpnb1 .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#wpnb1 .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}
#wpnb2 .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#wpnb2 .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}
#disEnrollment .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#disEnrollment .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#weightUpdate .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#weightUpdate .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#addServiceModal .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#addServiceModal .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#HealthLockerDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#HealthLockerDetails .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#addNewService .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#addNewService .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#pathwayUpdate .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#pathwayUpdate .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

#AddNewRecordDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#AddNewRecordDetails .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

.rmsc {
  --rmsc-h: 31px !important;
}

#EditRecordDetails .modal-header {
  display: flex;
  flex-shrink: 0;
  flex-direction: row !important;
  align-items: flex-start;
  align-content: center;
}
#EditRecordDetails .modal-title {
  font-size: 20px;
  color: #404040;
  font-weight: 600;
  line-height: 1.2;
}

.homeCareR-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 14px !important;
  padding: 6px 10px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}

.homeCareR-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}

.tag-hof{
  border: 1px solid #707070;
  border-radius: 18px;
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  line-height: 1.5;
  margin-left: 10px;
}

.tag-height-d{
  height: 100%;
  display: flex;
  align-content: stretch;
}



.profile-box-n1 .profile-card-title {
  font-size: 18px;
  color: #333;
  font-weight: 500;
}

.profile-box-n1{
  background: #EFFAFF 0% 0% no-repeat padding-box;
  border-radius: 12px;
  padding: 12px;
}

.profile-box-n2{
  background: #FFFACE;
  border-radius: 12px;
  padding: 12px;
}


.profile-box-n3{
  background: #E1FEEC;
  border-radius: 12px;
  padding: 12px;
}

.profile-box-n4{
  background: #FFEFEF;
  border-radius: 12px;
  padding: 12px;
}

.profile-box-n5{
  background: #EFF2FF;
  border-radius: 12px;
  padding: 12px;
}

.profile-card-subtitle {
  font-size: 14px;
  color: #5D5D5D;
  font-weight: 700;
}

.profile-card-count {
  font-size: 24px;
  color: #5D5D5D;
  font-weight: 700;
}

.profile-card-count span{
  font-size: 18px;
  font-weight: 500;
}

.profile-card-label {
  font-size: 14px;
  color: #5D5D5D;
  font-weight: 500;
}

.addPrograms-1-btn {
  color: #fff !important;
  background: #03335b !important;
  border: 1px solid #03335b !important;
  font-size: 15px !important;
  padding: 8px 14px !important;
  border-radius: 10px !important;
  line-height: 1.2 !important;
  font-weight: 600 !important;
}
.addPrograms-1-btn:hover {
  color: #fff !important;
  background: #1279be !important;
  border: 1px solid #1279be !important;
}
.status-big-text {
  font-size: 36px;
    color: #333;
    font-weight: 600;
    line-height: .8;
}

.UploadTeleHealthBookTable .dBiUCQ:nth-child(1), .UploadTeleHealthBookTable .gdBOih:nth-child(1) {
  max-width: 60px !important;
  min-width: 60px !important;
}

.UploadTeleHealthBookTable .dBiUCQ:nth-child(5), .UploadTeleHealthBookTable .gdBOih:nth-child(5) {
  max-width: 100px !important;
  min-width: 100px !important;
}

.loading-overlay-tele{
  background: rgb(255 255 255 / 90%) !important;
  border-radius: 30px !important;
}

.invalid-input{ 
  color: red;   
}

.profile-edu-img {
  border-radius: 140px;
  display: flex;
  height: 100px;
  object-fit: cover;
  width: 100px;
  border: 1px solid #BBBBBB !important;
  background-color: #578FFF !important;
  font-size: 36px;
  color: #ffffff;
  font-weight: 400;
  align-items: center;
  justify-content: center;
}

.profile-edu-title {
  text-decoration: none !important;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.profile-edu-subtitle {
  text-decoration: none !important;
  font-size: 12px;
  color: #787878;
  font-weight: 600;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.profile-edu-subtitle span{
  border: 1px solid #BBBBBB !important;
  color: #333;
  font-weight: 600;
  padding: 3px;
  border-radius: 20px;
}

.btn-edu-box {
  border: 1px solid #BBBBBB !important;
  border-radius: 10px !important;
  padding: 6px;
  height: 100%;
}


.profile-edu-progress {
  display: flex;
  height: 100px;
  width: 100px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.profile-edu-progress-bar{
 position: absolute;
 height: 100px;
 width: 100px;
}